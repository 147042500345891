import { Analytics } from "@vercel/analytics/react";
import { hasCookie } from "cookies-next";
import Head from "next/head";
import { ReactNode } from "react";

import { CommonProps } from "../lib/getCommonProps";
import siteUrl from "../lib/siteUrl";
import CookieConsent from "./CookieConsent";
import Footer from "./Footer";
import Header from "./Header";
import PreviewAlert from "./PreviewAlert";

type Props = {
  children?: ReactNode;
} & CommonProps;

const Layout = ({ children, ...commonProps }: Props) => {
  const cookieConsent = hasCookie("cookie-consent");

  return (
    <div className="flex flex-col justify-between h-screen subpixel-antialiased">
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "Corporation",
              name: "Binbircare",
              alternateName: "1001Care",
              legalName:
                "Binbirçare Bilişim Teknolojileri Danışmanlık Ticaret Ltd. Şti.",
              foundingDate: "2017",
              founders: [{ "@type": "Person", name: "Aslıhan Özkale" }],
              areaServed: "Turkey",
              slogan: "Sağlık ve Teknolojinin Buluştuğu Yer",
              url: "https://binbircare.com.tr/",
              logo: "https://binbircare.com.tr/logo.svg",
              email: "info@binbircare.com.tr",
              telephone: "+905469781001",
              address: {
                "@type": "PostalAddress",
                addressCountry: "Turkey",
                addressLocality: "İzmir",
                postalCode: "35170",
                streetAddress:
                  "Halkapınar Mahallesi 1202/2 Sokak Gıda Çarşısı İş Hanı No:31/816",
              },
              sameAs: [
                "https://www.linkedin.com/company/binbircare/about/",
                "https://www.facebook.com/Binbircare-647437692132008",
                "https://www.instagram.com/rayonex_turkiye/",
                "https://twitter.com/binbircare",
              ],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "WebPage",
              name: "Binbircare",
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "WebSite",
              name: "Binbircare",
              url: siteUrl,
            }),
          }}
        />
      </Head>
      <Header commonPosts={commonProps.commonPosts} />
      <PreviewAlert preview={commonProps.preview || false} />
      <main className="mb-auto">{children}</main>
      {!cookieConsent && <CookieConsent />}
      <Analytics />
      <Footer commonProducts={commonProps.commonProducts} />
    </div>
  );
};

export default Layout;
