import { StaticImageData } from "next/image";
import Link from "next/link";

import BlurImage from "./BlurImage";

type Props = {
  name?: string | null;
  link: string;
  image: StaticImageData | string;
};

const MenuCard = (props: Props) => (
  <Link
    href={props.link}
    key={props.name}
    className="text-green-500 bg-white hover:bg-green-500 hover:opacity-90 hover:text-white"
  >
    <div className="hover:bg-gray-50">
      <div className="w-full overflow-hidden aspect-w-9 aspect-h-9">
        <BlurImage
          src={props.image}
          alt={props.name!}
          className="object-cover w-full h-full"
          fill
        />
        <div className="hover:bg-green-500 opacity-10" />
      </div>
    </div>
    <h2 className="py-12 text-4xl font-extrabold text-center">{props.name}</h2>
  </Link>
);

export default MenuCard;
