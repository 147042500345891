import axios from "axios";
import Link from "next/link";
import { useForm } from "react-hook-form";

import { CommonProps } from "../lib/getCommonProps";
import siteUrl from "../lib/siteUrl";
import socialMedias from "../lib/socialMedias";

type SubscribitionForm = {
  email: string;
};

type Props = {
  commonProducts: CommonProps["commonProducts"];
};

const Footer = ({ commonProducts }: Props) => {
  const year = new Date().getFullYear();

  const navigation = {
    resources: [
      { name: "Makaleler", href: "/makaleler" },
      { name: "Etkinlikler", href: "/etkinlikler" },
    ],
    company: [{ name: "Kurumsal", href: "/kurumsal" }],
    legal: [
      { name: "Çerez Politıkası", href: "/cerez-politikasi" },
      { name: "Gizlilik Politikası", href: "/gizlilik-politikasi" },
      {
        name: "Ticari İletilerin Gönderilmesine İlişkin Açık Rıza Metni",
        href: "/ticari-ileti-goenderilmesine-iliskin-acik-riza-beyani",
      },
      {
        name: "Kişisel Verilerin Korunması ve İşlenmesi Politikası",
        href: "/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi",
      },
      {
        name: "İnternet Kullanıcıları Aydınlatma Metni",
        href: "/aydinlatma-metni",
      },
      { name: "İlgili Kişi Başvuru Formu", href: "/ilgili-kisi-basvuru-formu" },
      {
        name: "Tedarikçiler İçin Aydınlatma Metni",
        href: "/tedarikciler-icin-aydinlatma-metni",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubscribitionForm>();
  const onSubmit = handleSubmit((data) =>
    axios
      .post(siteUrl + "/api/subscribe", data)
      .catch((error) => console.error(error))
  );

  return (
    <footer className="bg-white shadow-2xl" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Ürünler
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {commonProducts.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={"/rayonex/" + item.slug}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Destek
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Hakkımızda
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Yasal
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href}>
                        <span className="text-base text-gray-500 cursor-pointer hover:text-gray-900">
                          {item.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
              Bültenimize abone olun
            </h3>
            <p className="mt-4 text-base text-gray-500">
              Bültenimize abone olmak için e-posta adresinizi girin.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md" onSubmit={onSubmit}>
              <label htmlFor="email-address" className="sr-only">
                E-posta adresiniz
              </label>
              <input
                type="email"
                autoComplete="email"
                {...register("email", { required: true })}
                className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-green-500 focus:border-green-500 focus:placeholder-gray-400"
                placeholder="E-posta adresinizi girin"
              />
              {errors.email && <p>E-posta adresi zorunlu</p>}
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-400 border border-transparent rounded-md hover:bg-green-700 focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Üye ol
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="pt-8 mt-8 border-t border-gray-200 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {socialMedias.map((item) => (
              <Link
                href={item.href}
                key={item.name}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="w-6 h-6" aria-hidden="true" />
              </Link>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {year} Binbirçare Bilişim Teknolojileri Danışmanlık Ticaret
            Ltd. Şti.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
