import { signIn, useSession } from "next-auth/react";

const LoginButton = () => {
  const { status } = useSession();

  if (status === "loading") {
    return (
      <div className="flex space-x-4 animate-pulse">
        <div className="w-24 h-10 rounded bg-slate-700" />
      </div>
    );
  }

  return (
    <a
      className="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white bg-green-400 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-green-700"
      onClick={() => signIn()}
    >
      Giriş Yap
    </a>
  );
};

export default LoginButton;
