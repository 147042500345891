import Facebook from "../components/Icons/Facebook";
import Instagram from "../components/Icons/Instagram";
import YouTube from "../components/Icons/YouTube";

const socialMedias = [
  {
    name: "YouTube",
    href: "https://www.youtube.com/channel/UC7MxKG3aH7dBod0XCWcpozA",
    icon: YouTube,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/rayonex_turkiye",
    icon: Instagram,
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/RayonexTurkiye",
    icon: Facebook,
  },
];

export default socialMedias;
