import Polar1000 from "../public/polar-1000.png";
import Ps10 from "../public/ps-10.png";
import Rayoguard from "../public/rayoguard.png";
// import RayonexPyramids from "../public/rayonex-pyramids.jpg";

export const categories = [
  {
    name: "PS 1000 Polar",
    href: "/rayonex?category=rayonex-ps-1000-polar",
    image: Polar1000,
  },
  {
    name: "Rayocomp PS 10",
    href: "/rayonex?category=rayonex-rayocomp-ps-10",
    image: Ps10,
  },
  {
    name: "Taşınabilir Cihazlar",
    href: "/rayonex?category=tasinabilir-cihazlar",
    image: Rayoguard,
  },
  // {
  //   name: "Etkinlikler",
  //   href: "/etkinlikler",
  //   image: RayonexPyramids,
  // },
];
