

import { ArrowRightIcon, UserIcon } from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";

const Avatar = () => {
  const { data: session, status } = useSession();

  if (status === "loading") {
    return (
      <div className="flex space-x-4 animate-pulse">
        <div className="rounded-full w-9 h-9 bg-slate-700" />
        <div className="flex-1 py-1 space-y-3">
          <div className="w-20 h-2 rounded bg-slate-700" />
          <div className="w-20 h-2 rounded bg-slate-700" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Link href="/hesabim" className="flex-shrink-0 block group">
        <div className="flex items-center">
          <div>
            {session?.user?.image ? (
              <Image
                className="inline-block rounded-full"
                src={session?.user?.image}
                alt="User profile picture"
                width={36}
                height={36}
              />
            ) : (
              <UserIcon className="inline-block w-8 h-8 pr-1 rounded-full" />
            )}
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
              {session?.user?.name}
            </p>
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              Hesabımı göster
            </p>
          </div>
        </div>
      </Link>
      <ArrowRightIcon
        className="w-5 h-5 ml-3 cursor-pointer"
        onClick={() => signOut()}
      />
    </>
  );
};

export default Avatar;
