import { Popover, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  InformationCircleIcon,
  MenuIcon,
  NewspaperIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Image from "next/image";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { Fragment } from "react";

import classNames from "../lib/classNames";
import { CommonProps } from "../lib/getCommonProps";
import { categories } from "../lib/menus";
import socialMedias from "../lib/socialMedias";
import Logo from "../public/logo.svg";
import Avatar from "./Avatar";
import BlurImage from "./BlurImage";
import LoginButton from "./LoginButton";
import MenuCard from "./MenuCard";

const callsToAction = [
  { name: "Bize Ulaşın", href: "#contact", icon: PlayIcon },
  { name: "Bizi Arayın", href: "tel:+905469781001", icon: PhoneIcon },
];

const company = [
  { name: "Kurumsal", href: "/kurumsal", icon: InformationCircleIcon },
  { name: "Makaleler", href: "/makaleler", icon: NewspaperIcon },
  { name: "Etkinlikler", href: "/etkinlikler", icon: CalendarIcon },
  { name: "Gizlilik", href: "/gizlilik-sozlesmesi", icon: ShieldCheckIcon },
];

const legal = [
  { name: "Çerez Politıkası", href: "/cerez-politikasi" },
  { name: "Gizlilik Politikası", href: "/gizlilik-politikasi" },
  {
    name: "Ticari İletilerin Gönderilmesine İlişkin Açık Rıza Metni",
    href: "/ticari-ileti-goenderilmesine-iliskin-acik-riza-beyani",
  },
  {
    name: "Kişisel Verilerin Korunması ve İşlenmesi Politikası",
    href: "/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi",
  },
  {
    name: "İnternet Kullanıcıları Aydınlatma Metni",
    href: "/aydinlatma-metni",
  },
  { name: "İlgili Kişi Başvuru Formu", href: "/ilgili-kisi-basvuru-formu" },
  {
    name: "Tedarikçiler İçin Aydınlatma Metni",
    href: "/tedarikciler-icin-aydinlatma-metni",
  },
];

type Props = {
  commonPosts: CommonProps["commonPosts"];
};

const Header = ({ commonPosts }: Props) => {
  const { data: session } = useSession();

  return (
    <header>
      <Popover className="relative bg-white">
        <div
          className="absolute inset-0 z-30 shadow pointer-events-none"
          aria-hidden="true"
        />
        <div className="relative z-20">
          <div className="flex items-center justify-between px-4 py-5 mx-auto sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <Link href="/">
              <div className="cursor-pointer">
                <span className="sr-only">Binbircare</span>
                <Image
                  src={Logo}
                  alt="Binbircare Logo"
                  priority={true}
                  className="w-auto h-12 sm:h-16"
                />
              </div>
            </Link>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="w-6 h-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
              <Popover.Group as="nav" className="flex space-x-10">
                <Popover>
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        )}
                      >
                        <span>Ürünler</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 z-10 hidden transform bg-white shadow-lg md:block top-full">
                          <div
                            className="grid px-4 pt-6 pb-6 mx-auto gap-y-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:pt-8 lg:grid-cols-4 lg:px-8 lg:pt-12 xl:pt-16"
                            onClick={close}
                          >
                            {categories.map((product) => (
                              <MenuCard
                                name={product.name}
                                image={product.image}
                                link={product.href || "#"}
                                key={product.name}
                              />
                            ))}
                          </div>
                          <div className="pb-10 ml-8 text-lg font-semibold">
                            <Link
                              href="/rayonex"
                              className="font-medium text-green-600 hover:text-green-500"
                            >
                              Tüm ürünleri gör{" "}
                              <span aria-hidden="true">&rarr;</span>
                            </Link>
                          </div>
                          <div className="bg-gray-50">
                            <div className="px-4 py-5 mx-auto space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                              {callsToAction.map((item) => (
                                <div key={item.name} className="flow-root">
                                  <a
                                    href={item.href}
                                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                                  >
                                    <item.icon
                                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-3">{item.name}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Link
                  href="/etkinlikler"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Etkinlikler
                </Link>
                <Link
                  href="/makaleler"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Makaleler
                </Link>
                <Link
                  href="/kurumsal"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Kurumsal
                </Link>
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                      >
                        <span>Daha fazlası</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 z-10 hidden transform shadow-lg md:block top-full">
                          <div className="absolute inset-0 flex">
                            <div className="w-1/2 bg-white" />
                            <div className="w-1/2 bg-gray-50" />
                          </div>
                          <div className="relative grid grid-cols-1 mx-auto max-w-7xl lg:grid-cols-2">
                            <nav className="grid px-4 py-8 bg-white gap-y-10 sm:grid-cols-3 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                              <div>
                                <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                  Kurumsal
                                </h3>
                                <ul role="list" className="mt-5 space-y-6">
                                  {company.map((item) => (
                                    <li key={item.name} className="flow-root">
                                      <a
                                        href={item.href}
                                        className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                                      >
                                        <item.icon
                                          className="flex-shrink-0 w-6 h-6 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-4">
                                          {item.name}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                  Kaynaklar
                                </h3>
                                <ul role="list" className="mt-5 space-y-6">
                                  {socialMedias.map((item) => (
                                    <li key={item.name} className="flow-root">
                                      <Link
                                        href={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                                      >
                                        <item.icon
                                          className="flex-shrink-0 w-6 h-6 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-4">
                                          {item.name}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                  Yasal
                                </h3>
                                <ul role="list" className="mt-5 space-y-6">
                                  {legal.map((item) => (
                                    <li key={item.name} className="flow-root">
                                      <Link
                                        href={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                                      >
                                        {/* <item.icon
                                          className="flex-shrink-0 w-6 h-6 text-gray-400"
                                          aria-hidden="true"
                                        /> */}
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </nav>
                            <div className="px-4 py-8 bg-gray-50 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                              <div>
                                <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                  Blogumuzdan
                                </h3>
                                <ul role="list" className="mt-6 space-y-6">
                                  {commonPosts.map((post) => (
                                    <li key={post.id} className="flow-root">
                                      <Link
                                        href={"/makaleler/" + post.slug}
                                        className="flex p-3 -m-3 rounded-lg hover:bg-gray-100"
                                      >
                                        <div className="flex-shrink-0 hidden sm:block">
                                          <BlurImage
                                            className="object-cover w-32 h-20 rounded-md"
                                            src={
                                              post.coverImage?.url ||
                                              "/logo-with-background.webp"
                                            }
                                            alt={post.imageAlt || post.title}
                                            width={128}
                                            height={80}
                                          />
                                        </div>
                                        <div className="flex-1 w-0 sm:ml-8">
                                          <h4 className="text-base font-medium text-gray-900 truncate">
                                            {post.title}
                                          </h4>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {post.excerpt}
                                          </p>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="mt-6 text-sm font-medium">
                                <Link
                                  href="/makaleler"
                                  className="text-green-600 hover:text-green-500"
                                >
                                  {" "}
                                  Tüm gönderileri gör{" "}
                                  <span aria-hidden="true">&rarr;</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
              <div className="flex items-center md:ml-12">
                {!session ? (
                  <>
                    <a
                      href="#contact"
                      type="button"
                      className="inline-flex items-center px-4 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Bize Ulaşın
                    </a>
                    {process.env.NEXT_PUBLIC_AUTH_SWITCH === "true" && (
                      <LoginButton />
                    )}
                  </>
                ) : (
                  <Avatar />
                )}
              </div>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform md:hidden"
          >
            <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
              <div className="px-5 pt-5 pb-6 sm:pb-8">
                <div className="flex items-center justify-between">
                  <Link href="/">
                    <Image
                      src={Logo}
                      alt="Binbircare Logo"
                      width={190}
                      height={60}
                      priority={true}
                      className="w-auto h-8"
                    />
                  </Link>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Menüyü kapat</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 sm:mt-8">
                  <nav>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                      {categories.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50"
                        >
                          <div className="text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="mt-8 text-base">
                      <a
                        href="#"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {" "}
                        Tüm ürünleri gör <span aria-hidden="true">&rarr;</span>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="px-5 py-6">
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    href="/kurumsal"
                    className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700"
                  >
                    Kurumsal
                  </Link>
                  <Link
                    href="/blog"
                    className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700"
                  >
                    Blog
                  </Link>
                  <a
                    href="#contact"
                    className="text-base font-medium text-gray-900 rounded-md hover:text-gray-700"
                  >
                    İletişim
                  </a>
                </div>
                {process.env.NEXT_PUBLIC_AUTH_SWITCH === "true" && (
                  <div className="mt-6">
                    {!session ? (
                      <Link
                        href="/giris"
                        className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-400 border border-transparent rounded-md shadow-sm hover:bg-green-700"
                      >
                        Giriş yap
                      </Link>
                    ) : (
                      <Avatar />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};

export default Header;
