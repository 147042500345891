import { setCookie } from "cookies-next";
import Link from "next/link";
import { useState } from "react";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    setCookie("cookie-consent", "accepted", {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 px-6 pb-6 pointer-events-none">
      <div className="max-w-xl p-6 bg-white shadow-lg pointer-events-auto rounded-xl ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 text-gray-900">
          Bu websitesi çerez kullanmaktadır.{" "}
          <Link
            href="/cerez-politikasi"
            className="font-semibold text-indigo-600"
          >
            Çerez politikamızı
          </Link>{" "}
          inceleyebilirsiniz.
        </p>
        <div className="flex items-center mt-4 gap-x-5">
          <button
            type="button"
            className="px-3 py-2 text-sm font-semibold text-white bg-green-500 rounded-md shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            onClick={handleAccept}
          >
            Kabul et
          </button>
          {/* <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Reject all
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
